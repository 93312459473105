export class User {

  roles: string;

  constructor(public username: string,
              private token: string,
              private refreshToken: string,
              private expirationDate: Date) {}

   getToken(): string{
     if (!this.expirationDate || new Date() > this.expirationDate) {
      return null;
     }
     return this.token;
   }

}
