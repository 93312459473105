import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const x = localStorage.getItem('userData');
    if (x) {
      const y = new Date(JSON.parse(x).expirationDate);
      if (y > new Date()) {
        try {
          return true;
        } catch (err) {
        }
      }
    }
    this.authService.logout();
    return this.router.parseUrl('/auth');
  }
}
