import {MapService} from './../map.service';
import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {LatLngBoundsLiteral, CRS, Map, Layer, layerGroup} from 'leaflet';
import {NgZone} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Globals} from '../global';
import jwtDecode from 'jwt-decode';
import {User} from '../auth/user.model';


@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, OnDestroy {
  bounds: LatLngBoundsLiteral;


  baseLayerControls: { overlays: { [id: string]: Layer }, baselayer: { [id: string]: Layer } } = {
    overlays: {},
    baselayer: {}
  };


  maxBounds: LatLngBoundsLiteral;

  layers = [];
  layer;
  map;
  admin = false;

  options = {
    crs: CRS.Simple,
    minZoom: -4,
    maxZoom: 1,
    wheelPxPerZoomLevel: 400,
    zoomDelta: 0.3,
    zoomSnap: 0,
    zoomAnimation: false,
    markerZoomAnimation: false,
    inertia: false,
    maxBoundsViscosity: 1.0,
  };

  mySubscription: any;
  serviceSubscription: any;
  missingMapData: boolean;
  missingMapDataMaps: boolean;
  missingMapDataLayers: boolean;


  fitBounds: LatLngBoundsLiteral;


  constructor(private mapService: MapService,
              private ngZone: NgZone,
              private changeDetectorRef: ChangeDetectorRef,
              private router: Router,
              private globals: Globals,
              private detector: ChangeDetectorRef) {

    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };
    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
    if (this.serviceSubscription) {
      this.serviceSubscription.unsubscribe();
    }
  }

  async ngOnInit() {


    if (this.mapService.currentMapNr === null) {
      this.missingMapData = true;
      this.missingMapDataMaps = true;


      await this.mapService.firstMap();

      this.missingMapData = false;
      this.missingMapDataMaps = false;
    }


    const map = await this.mapService.getMapGrouped(this.mapService.currentMapNr);

    this.layers = [...map.layers.map(lay => lay.layerGroup)];
    this.changeDetectorRef.detectChanges();

    for (const layer of map.layers) {
      this.baseLayerControls.baselayer[layer.name] = layer.layerGroup;
    }

    if (map.layers.length < 1) {
      this.missingMapData = true;
      this.missingMapDataLayers = true;
    }

    this.bounds = map.bounds;
    if (this.bounds) {
      this.maxBounds = [
        [this.bounds[0][0] - 1000, this.bounds[0][1] - 1000],
        [this.bounds[1][0] + 1000, this.bounds[1][1] + 1000],
      ];
      this.fitBounds = Array.from(this.bounds);
    }

    this.serviceSubscription = this.mapService.currentMap.subscribe(async value => {
      this.testReload();
    });
  }


  onMapReady(map: Map): void {

    this.map = map;
    this.map.on('baselayerchange', e => {
      this.layer = e.layer;

      const userData = JSON.parse(localStorage.getItem('userData'));
      const decoded: User = jwtDecode(userData.token);
      const roles = decoded.roles;

      if (roles.includes('ROLE_ADMIN')) {
        const layername = this.currentLayer();
        this.mapService.downloadStatus(layername).subscribe(res => {
          this.downloadExists(res);
        });
      }
    });
    map.attributionControl.setPrefix('<a href="#">© Decisio 2021</a> | ' +
      '<a href="#">Impressum</a> | ' +
      '<a href="#">Datenschutz</a>'
    );
    requestAnimationFrame(() => map.invalidateSize());
  }

  testReload(): void {
    if (this.globals.reload === true) {
      this.globals.reload = false;
      this.router.navigateByUrl('/map');
    }
  }


  currentLayer(): string {
    let layername = null;
    for (const baseLayerKey in this.baseLayerControls.baselayer) {
      if (this.baseLayerControls.baselayer[baseLayerKey] === this.layer) {
        layername = baseLayerKey;
      }
    }
    return layername;
  }

  getDownload(): void {
    const layername = this.currentLayer();
    this.mapService.download(layername);
  }

  checkData(): void {
    this.missingMapData = true;
  }

  downloadExists(status: boolean): void{
    this.admin = status;
    this.detector.detectChanges();
  }
}
