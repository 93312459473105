<!--<section fxFlex fxLayoutAlign="center center" *ngIf="missingMapData">-->
<!--  <mat-card>-->
<!--    <mat-card-title> Ein Fehler ist aufgetreten </mat-card-title>-->
<!--    <mat-card-content *ngIf="missingMapDataMaps">-->
<!--      <span>Es scheint, dass Ihrem Account noch keine Karten zugewiesen wurden.</span>-->
<!--      <br>-->
<!--      <span>Falls es sich dabei um einen Irrtum handelt kontaktieren Sie uns bitte.</span>-->
<!--    </mat-card-content>-->
<!--    <mat-card-content *ngIf="missingMapDataLayers">-->
<!--      <span>Es scheint, dass Ihrem Account für diese Rubrik noch keine Karten zugewiesen wurden.</span>-->
<!--      <br>-->
<!--      <span>Falls es sich dabei um einen Irrtum handelt kontaktieren Sie uns bitte.</span>-->
<!--    </mat-card-content>-->
<!--  </mat-card>-->
<!--</section>-->

<div id="map-wrapper">
<div
  id = "map"
  fxFlex
  leaflet
  [leafletOptions]="options"
  [leafletBaseLayers]="baseLayerControls.baselayer"
  [leafletFitBounds]="fitBounds"
  [leafletMaxBounds]="maxBounds"
  (leafletMapReady)="onMapReady($event)"
>
</div>
  <div id="button-wrapper" [hidden]="!admin">
  <button
    leaflet-bottom
    leaflet-right
    mat-icon-button
    matTooltip="Karte herunterladen"
    (click)="this.getDownload()"
  ><mat-icon>file_download</mat-icon></button>
  </div>
</div>

