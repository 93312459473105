<head>

  <title>title</title>
</head>
<div fxLayout="column" fxFlexFill>
  <mat-toolbar fxFlex="none">
    <img src="assets/decisio_logo-map.svg">

    <span fxFlex></span>
    <mat-form-field appearance="fill" *ngIf="currentUser()">
      <mat-label>Karte</mat-label>
      <mat-select [formControl]="selectedMap" required>
        <mat-option *ngFor="let map of maps" [value]="map.id">
          {{map.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <a
      mat-icon-button
      matTooltip="Webseite"
      href="https://www.decisio.online/map-home.php"
    >
      <mat-icon>public</mat-icon>
    </a>
    <button
      *ngIf="currentUser()"
      mat-icon-button
      matTooltip="Abmelden"
      (click)="logout()"
    >
      <mat-icon>exit_to_app</mat-icon>
    </button>
  </mat-toolbar>
  <div class="content" fxFlex fxLayout="column">
    <router-outlet></router-outlet>
  </div>
</div>
