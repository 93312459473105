<section fxFlex fxLayoutAlign="center center">
  <mat-card>
    <mat-card-title> Login </mat-card-title>
    <mat-card-content>
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" fxLayout="column">
        <mat-error *ngIf="error">{{error}}</mat-error>
        <mat-form-field>
          <mat-label>Username</mat-label>
          <input matInput type="username" formControlName="username" required />

        </mat-form-field>
        <mat-form-field>
          <mat-label>Passwort</mat-label>
          <input matInput type="password" formControlName="password" required />
        </mat-form-field>
        <button
          mat-flat-button
          type="submit"
          [disabled]="!loginForm.valid"
        >
          Anmelden
        </button>
        <mat-label class="new-user-add"><span><br>Noch kein User? <a href="https://www.decisio.online/map/Decisio_Welt">Informieren Sie sich jetzt</a> oder kontaktieren <br> Sie uns für eine kostenlose Live-Demo.</span></mat-label>
      </form>
    </mat-card-content>
  </mat-card>
</section>
