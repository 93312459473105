import { HttpClient } from '@angular/common/http';
import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import {
  LatLngBoundsLiteral,
  SVGOverlay,
  svgOverlay,
  GeoJSON,
  geoJSON,
  circle,
  LayerGroup,
  layerGroup,
} from 'leaflet';
import {Observable, Subject} from 'rxjs';
import {timeout} from 'rxjs/operators';
import {Router} from '@angular/router';
import {Globals} from './global';
import {parseJson} from '@angular/cli/utilities/json-file';

@Injectable({
  providedIn: 'root',
})
export class MapService {
  readonly urlNewLive = 'https://www.decisio.online';
  readonly urlOldLive = 'http://decisio.online.dedi3145.your-server.de';
  readonly urlDdev = 'https://decisio-pimcore.ddev.site';
  readonly urlTest = 'http://test-decisio-pimcore.decisio.online.dedi3145.your-server.de';

  readonly url = this.urlNewLive;

  readonly apiUrl = this.url + '/api/';
  readonly mapUrl = this.apiUrl + 'map';
  readonly mapsUrl = this.apiUrl + 'maps';
  readonly featuresUrl = this.apiUrl + 'features';
  readonly downloadUrl = this.apiUrl + 'layer/download';
  readonly downloadNameUrl = this.apiUrl + 'layer/download/name';
  private renderer: Renderer2;
  currentMap: Subject<number> = new Subject<number>();
  maps: Subject<any> = new Subject<any>();

  currentMapNr = null;

  constructor(
    private httpClient: HttpClient,
    rendererFactory: RendererFactory2,
    private router: Router,
    private globals: Globals,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  download(name: string): void{
    console.log(name);
    // @ts-ignore
    const data = this.httpClient.post<Blob>(this.downloadUrl, {params: {layerName: String(name)}}, {responseType: 'blob'}).subscribe(res => {
        this.httpClient.post(this.downloadNameUrl, {params: {layerName: String(name)}}).subscribe(fileName => {
        this.downloadBlob(res, fileName);
      });
      });
  }

  downloadStatus(name: string): Observable<any>{
    return this.httpClient
      .post(this.downloadUrl + '/status', {params: {layerName: String(name)}});
  }

  newMap(sub: number): void {
    this.globals.reload = true;
    this.currentMapNr = sub;
    this.currentMap.next(sub);


  }

  async getAllMapsAsLayerGroups(): Promise<{
    bounds: LatLngBoundsLiteral;
    layers: LayerGroup;
    name: string;
    id: number;
  }[]> {
    const {maps} = await this.getMaps();
    return Promise.all(maps.sort().map((map) => this.getMapAsLayerGroup(map.id)));
  }

  async getMaps(): Promise<{ maps: { id: number, name: string }[] }> {
    const data = (await this.httpClient.post(this.mapsUrl, {}).toPromise()) as {
      maps: { id: number, name: string }[];
    };
    this.maps.next(data);
    this.currentMapNr = data.maps[0].id;
    return data;
  }

  async firstMap(){
    const data = await this.httpClient.post(this.mapsUrl, {}).toPromise() as {
      maps: { id: number, name: string }[];
    };
    this.currentMapNr = data.maps[0].id;
  }

  async hasMap(){
    const data = await this.httpClient.post(this.mapsUrl, {}).subscribe(res => {});
  }

  async getMapAsLayerGroup(
    id: number
  ): Promise<{
    bounds: LatLngBoundsLiteral;
    layers: LayerGroup;
    name: string;
    id: number;
  }> {
    const {bounds, layers, name} = await this.getMap(id);
    return {
      bounds,
      layers: layerGroup(layers.map((lay) => lay.svg)),
      name,
      id,
    };
  }

  async getMapGrouped(
    mapId: number
  ): Promise<{
    bounds: LatLngBoundsLiteral;
    layers: { layerGroup: LayerGroup; name: string; id: number }[];
    name: string;
    id: number;
  }> {
    const {bounds, layers, name, id} = await this.getMap(mapId);
    const newLayers: { layerGroup: LayerGroup; name: string; id: number }[] = [];
    const features = await this.getFeatures(mapId);
    for (const layer of layers) {
      let localLayerGroup: LayerGroup;
      if (features[layer.id]) {
        localLayerGroup = layerGroup([layer.svg, features[layer.id]]);
      } else {
        localLayerGroup = layerGroup([layer.svg]);
      }
      newLayers.push({
        layerGroup: localLayerGroup,
        name: layer.name,
        id: layer.id,
      });
    }
    return {bounds, layers: newLayers, name, id};
  }

  async getMap(
    id: number
  ): Promise<{
    bounds: LatLngBoundsLiteral;
    layers: { svg: SVGOverlay; name: string; id: number }[];
    name: string;
    id: number;
  }> {
    const data = (await this.httpClient
      .post(this.mapUrl, {params: {mapId: String(id)}})
      .toPromise()) as {
      layers: { svg: string; id: number; name: string }[];
      name: string;
    };
    const layers = [];
    let bounds: LatLngBoundsLiteral;
    for (const layer of data.layers) {
      const svgLayer = await this.getLayer(this.url + layer.svg);
      layers.push({svg: svgLayer.overlay, name: layer.name, id: layer.id});
      bounds ??= svgLayer.bounds;
    }
    return {layers, bounds, name: data.name, id};
  }

  async getLayer(
    url: string
  ): Promise<{ overlay: SVGOverlay; bounds: LatLngBoundsLiteral }> {
    let bounds: LatLngBoundsLiteral;
    const data = await this.httpClient
      .get(url, {responseType: 'text'})
      .toPromise();
    const div = this.renderer.createElement('DIV');
    div.innerHTML = data;
    const svg = div.querySelector('svg') as SVGElement;
    // get the Bounds of the SVG
    const boundsArray = svg
      .getAttribute('viewBox')
      .split(/\s+|,/)
      .map((num) => Number(num));
    if (boundsArray.length === 4) {
      bounds = [
        [boundsArray[1], boundsArray[0]],
        [boundsArray[3], boundsArray[2]],
      ];
    }
    if (svg) {
      const overlay = svgOverlay(svg, bounds);
      svg.remove();
      return {overlay, bounds};
    } else {
      throw new Error('Error');
    }
  }

  async getFeatures(id: number): Promise<{ [id: number]: GeoJSON }> {
    const data = await this.httpClient
      .get(this.featuresUrl, {params: {mapId: String(id)}})
      .toPromise();
    const result: { [id: number]: GeoJSON } = {};
    for (const [key, value] of Object.entries(data)) {
      result[key] = geoJSON(value as any, {
        pointToLayer: (feature, latlng) => {
          return circle(latlng, {
            stroke: false,
            radius:
              (feature.properties?.geomTypeProperties?.diameter ?? 16) / 2,
            className: 'circle',
          });
        },
        onEachFeature: (feature, layer) => {
          // does this feature have a property named text?
          if (feature.properties?.text) {
            const title = `<h3>${feature.properties.label}</h3>`;
            const link = `<a href='${feature.properties.link}' target='_blank'>Mehr Infos</a>`;
            let text = title + feature.properties.text;
            if (feature.properties.link) {
              text += link;
            }
            layer.bindPopup(text);
          }
        },
        style: (feature) => {
          return {
            color: '#ff0088',
            opacity: feature.properties?.display ? 1 : 0,
            fillOpacity: feature.properties?.display ? 1 : 0,
          };
        },
      });
    }
    return result;
  }
  downloadBlob(blob, name) {
    if (
      window.navigator &&
      window.navigator.msSaveOrOpenBlob
    ) {
      return window.navigator.msSaveOrOpenBlob(blob);
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = data;
    link.download = name;

    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      })
    );

    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
  }
}
