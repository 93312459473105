import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import {Observable, Subject, throwError} from 'rxjs';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {catchError, map, tap} from 'rxjs/operators';
import {User} from './user.model';
import jwtDecode from 'jwt-decode';
import {MapService} from '../map.service';

// Interface for the data received by the backend on login and refresh
export interface AuthResponseData{
  token: string;
  refresh_token: string;
}

// Interface for the token (loginToken) not the refreshToken
export interface Token {
  iat: number;
  exp: number;
  roles: [string];
  username: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  readonly urlNewLive = 'https://www.decisio.online/api/';
  readonly urlOldLive = 'http://decisio.online.dedi3145.your-server.de';
  readonly urlDdev = 'https://decisio-pimcore.ddev.site/api/';
  readonly urlTest = 'http://test-decisio-pimcore.decisio.online.dedi3145.your-server.de/api/';

  readonly url = this.urlNewLive;
  user = new Subject<User>();
  loggedIn: Subject<boolean> = new Subject<boolean>();

  constructor(private http: HttpClient, private router: Router, private mapService: MapService, ) {}

  // sends login-request to backend
  login(username: string, password: string): Observable<AuthResponseData>{
    return this.http.post<AuthResponseData>
    (this.url + 'login',
      {
        username,
        password,
      }
    ).pipe(tap(responseData => {
      this.handleAuthentication(username, responseData.token, responseData.refresh_token);
    })).pipe(catchError(err => this.handleError(err)));
  }

  // clears the user and localStorage 'userData' navigates back to auth
  logout(): void {
    this.user.next(null);
    localStorage.clear();
    this.router.navigate(['/auth']);
    this.mapService.currentMapNr = null;
  }




// determines the Error given on Login
  private handleError(errorRes: HttpErrorResponse): Observable<any>{
    let errorMessage = 'An unknown error occurd!';
    if (!errorRes.error.message){
      return throwError(errorMessage);
    }
    // Error for wrong username or wrong password
    else if (errorRes.error.message === 'Invalid credentials.') {
      errorMessage = 'wrong username or password';
      return throwError(errorMessage);
    } else {
      return throwError(errorMessage);
    }
  }

  // transforms the Token and saves the user in user and in the localStorage as userData
  private handleAuthentication(username: string, token: string, refreshToken: string): void{
    const decoded: Token =  jwtDecode(token);
    const expirationDate = new Date(decoded.exp * 1000);
    const user = new User(username, token, refreshToken, expirationDate);
    this.user.next(user);
    localStorage.setItem('userData', JSON.stringify(user));
  }

  // Logs the user back in on Site reload
  autoLogin(): void{
    const userData: {
      username: string;
      token: string;
      refreshToken: string;
      expirationDate: string;
    } = JSON.parse(localStorage.getItem('userData'));
    if (!userData){
      return;
    }
    const loadedUser = new User(userData.username, userData.token, userData.refreshToken, new Date(userData.expirationDate));
    if (loadedUser.getToken()){
      this.loggedIn.next(true);
      this.user.next(loadedUser);
      this.autoRefresh();
    }
  }

  // chooses when to refresh or if needed logs the user out -> calls refresh()
  autoRefresh(): void{
    if (localStorage.getItem('userData')){
    const expDateString = JSON.parse(localStorage.getItem('userData')).expirationDate;
    const expDate = new Date (expDateString);
    // logs the user out if the expirationDate is smaller or marginally higher then the current Date
    if (new Date().valueOf() > (expDate.valueOf() - 100)){
      this.logout();
    }else{
      // sets a Timer for the refresh if the expiration Date is more then 20 seconds in the Future
      if (new Date().valueOf() < (expDate.valueOf() - 20000)){
        setTimeout(() => { this.refresh(); }, (expDate.valueOf() - new Date().valueOf() - 20000));
        // instantly refreshes if the expiration Date is less then 20 seconds in the Future (but more then 0.1)
      } else{
        this.refresh();
      }
      }
    }
  }

  // triggers a refresh by sending the refreshToken to the Backend -> calls autoRefresh()
  refresh(): void{
    if (localStorage.getItem('userData')) {
      const refreshToken = JSON.parse(localStorage.getItem('userData')).refreshToken;
      this.http.post<AuthResponseData>(this.url + 'token/refresh',
        {
          refresh_token: refreshToken
        }).pipe(
          catchError(err => {
            this.logout();
            return throwError(err);
          })
      )
        .subscribe(responseData => {
        const decodeToken: Token = jwtDecode(responseData.token);
        // creating the new user as received from the backend
        const reloadedUser = new User(
          decodeToken.username,
          responseData.token,
          responseData.refresh_token,
          new Date(decodeToken.exp * 1000));
        this.user.next(reloadedUser);
        localStorage.setItem('userData', JSON.stringify(reloadedUser));
        this.autoRefresh();
      });
    }
  }
}
