import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {AuthService} from './auth/auth.service';

@Injectable()
export class JwtInterceptorService implements HttpInterceptor {

  constructor(private authService: AuthService){}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    const jwt = JSON.parse(localStorage.getItem('userData'));
    if (jwt) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ` + jwt.token
        }
      });
      // if (sessionStorage.getItem('refreshDate')) {
        // this.authService.startRefreshTokenTimer(sessionStorage.getItem('refreshDate'));
      // }
    }
    return next.handle(request);
  }
}
