import { FormControl } from '@angular/forms';
import { MapService } from './map.service';
import { AuthService } from './auth/auth.service';
import {Component, OnInit} from '@angular/core';
import {User} from './auth/user.model';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit{
  title = 'decisio-karte-ng';
  maps: {id: number, name: string}[] = [];
  selectedMap = new FormControl();
  user: User;

  constructor(
    private authService: AuthService,
    private mapService: MapService
  ) {
     this.authService.loggedIn.subscribe(responseData => {
       if (responseData === true) {
        this.mapService.getMaps().then((maps) => {
          this.maps = maps.maps;
          this.selectedMap.setValue(this.maps[0].id);
        });
        this.mapService.maps.subscribe(responseData2 => {
          this.maps = responseData2;
        });
        this.selectedMap.valueChanges.subscribe(sub => this.mapService.newMap(sub));
      }
    });
  }

  ngOnInit(): void {
    this.authService.autoLogin();
  }

  logout(): void {
    this.maps = [];
    this.authService.logout();
  }

  currentUser() {
    if (localStorage.getItem('userData')) {
      return true;
    }
  }
}
